import {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router";
import Checkbox from '@mui/material/Checkbox';
import useLogin from "../utils/useLogin";
import useSMS from "../utils/useSMS";
import { validatePhone, validateCode } from "../utils/validator";
import {bottomToast} from "../utils/toast";
import "./login.scss";
import logo from "../assets/logo.png"
import Agreement from "../components/Agreement";
export function Login() {

    const [phone, setPhone] = useState();
    const [code, setCode] = useState();
    const [checked, setChecked] = useState(false);
    const [agreementAlert, setAgreementAlert] = useState(false);

// for verification code
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("获取验证码");
    const [count, setCount] = useState(0);

    const agreementRef = useRef();

    const startCountdown = () => {
        setBtnDisabled(true);
        setCount(60);
    }

    const endCountdown = () => {
        setBtnText("获取验证码");
        setBtnDisabled(false);
    }

    const loginAlert = {
        codeSuccess: "获取验证码成功",
        loginSuccess: "登录成功",
        emptyPhone: "请输入你的手机号",
        emptyCode: "请输入你的验证码",
        invalidPhone: "请输入正确的手机号",
        invalidCode: "请输入正确的验证码",
    }

    const sms = useSMS(phone);
    const getSMS = async () => {
        if (validatePhone(phone)) {
            sms().then((res) => {
                if (res) {
                    bottomToast(loginAlert.codeSuccess);
                    startCountdown();
                } else {
                    bottomToast(loginAlert.invalidPhone);
                    endCountdown();
                }
            });
        } else if (!phone) {
            bottomToast(loginAlert.emptyPhone);
        } else {
            bottomToast(loginAlert.invalidPhone);
        }
    };

    const login = useLogin();
    const navigate = useNavigate();
    const handleConfirm = () => {
        if (!phone) {
            bottomToast(loginAlert.emptyPhone);
        } else if (!code) {
            bottomToast(loginAlert.emptyCode);
        } else if (!validatePhone(phone)) {
            bottomToast(loginAlert.invalidPhone);
        } else if (!validateCode(code)) {
            bottomToast(loginAlert.invalidCode);
        } else if (checked) {
            login(phone, code).then((res) => {
                if (!res) {
                    bottomToast(loginAlert.invalidCode);
                } else {
                    bottomToast(loginAlert.loginSuccess);
                    navigate("/");
                }
            })
        } else {
            setAgreementAlert(true);
            bottomToast("请仔细阅读并勾选用户协议");
        }
    };

    useEffect(() => {
        if (count > 0) {
            setBtnText(count +"s");
            setTimeout(() => {
                if(count > 1){
                    setCount(count - 1);
                }else {
                    endCountdown();
                }
            }, 1000);
        }
    }, [count]);

    return (
        <div className="login">
            <div className="title-container">
                <img className="title" src={logo} />
            </div>


            <div className="input-container">
                <input className="phone" type="tel" size="1" maxLength="11" onChange={(e) => {setPhone(e.target.value)}} placeholder="请输入手机号"/>
            </div>


            <div className="input-container">
                <div className="input-wrapper">
                    <input className="code" type="tel" size="1" maxLength="6" onChange={(e) => {setCode(e.target.value)}} placeholder="请输入验证码"/>
                </div>
                <button onClick={getSMS} disabled={btnDisabled} className="code-button">
                    {btnText}
                </button>
            </div>

            <div className="agreement-container">
                <Checkbox onChange={(e) => {setChecked(e.target.checked)}} sx={{ padding: "0", color: (agreementAlert&&!checked)?"red":"#7C7C7C", '&.Mui-checked': {
                        color: "rgba(184, 60, 46, 1)",
                    },'& .MuiSvgIcon-root': { fontSize: "15px" } }} />
                <div className="agreement-label">
                    <span style={{color: (agreementAlert&&!checked)?"red":"#7C7C7C" }}>我已阅读并同意</span>
                    {/*<span className='theme-color' style={{color: (agreementAlert&&!checked)?"red":''}}>《用户协议》</span>*/}
                    <div className="theme-color" style={{color: (agreementAlert&&!checked)?"red":""}}
                         onClick={()=>{agreementRef.current.openAgreement()}}>《用户协议》</div>
                </div>
            </div>

            <div className="input-container">
                <button className={`next ${checked?"checked":null} full-length-button`} onClick={handleConfirm}>登录</button>
            </div>
            <Agreement ref={agreementRef}/>
        </div>

    );
}
