const responseCode = {
  success                             : "20000",
	redisLockFailedToGet                : "20016",
	requestIdAlreadyReceived            : "20017",
	userNFTRecordNotFound               : "20020",
	// 通用 GENERAL: 20---\
	unknownFailure                      : "30000",
	invalidParam                        : "20001",
	cannotAccessToThisResource          : "20002",
	requestHeaderParamIsEmpty           : "20006",
	notifyOpenidError                   : "20007",
	requestUnmarshalError               : "20009",
	sendMessageError                    : "20012",
	callPortalError                     : "20013",
	databaseError                       : "20014",
	blockchainError                     : "20015",
	marshalError                        : "20021",
	recordNotFound                      : "20022",

	// 鉴权相关 AUTH: 21---
	errorAuthCheckTokenFail             : "20003",
	errorAuthCheckTokenTimeout          : "20004",
	errorAuthToken                      : "20005",

	// 用户相关 USER: 22---
	invalidUser                         : "20011",
	userNotAuth                         : "20010",
	userNotFound                        : "23000",
	invalidAccessTokenDueToRemoteLogin  : "23001",

	// APP相关: 23---
	appIdError                          : "20008",

	// 兑换码: 24---
	redemptionCodeInvalid               : "20018",
	redemptionCodeAlreadyUsed           : "20019"
}

export { responseCode };