import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {AuthContext, serverAddress} from "../App";
import {Image, Layer, Rect, Stage, Text} from "react-konva";
import BackButton from "../components/BackButton";
import "./poster.scss";
import point from "../assets/point.png"
import Loading from "../components/Loading";


export default function Poster(){

    const { state } = useContext(AuthContext);


    const ratio = window.devicePixelRatio;
    const [deviceWidth] = useState(window.innerWidth);
    const stageRef = useRef();
    const output = useRef();
    const [nft, setNft] = useState();
    const [loaded, setLoaded] = useState(false);
    const [templateLoaded, setTemplateLoaded] = useState(false);
    const [nftLoaded, setNftLoaded] = useState(false);
    const [frame, setFrame] = useState();
    const [frameLoaded, setFrameLoaded] = useState(false);
    const [titleLoaded, setTitleLoaded] = useState(false);
    const [title,setTitle] = useState();

    const [templateImg, setTemplate] = useState();
    const [tokenId, setTokenId] = useState();
    const [owner, setOwner] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const params = useParams();

    const [client, setOssClient] = useState(null);

    const imgWidth = 375;
    const uiWidth = 375;
    const uiHeight = 812;
    const scale = deviceWidth / uiWidth;

    const getSubStr = (str) => {
        let subStr1 = str.substr(0, 7);
        let subStr2 = str.substr(str.length - 19, 19);
        return subStr1 + "..." + subStr2;
    };

    useEffect(() => {
        if (state.isLogin) {
            fetch(serverAddress + "/poster/ststoken",{
                method: "GET",
                headers: {"accessToken": state.accessToken},
            }).then((res) => {
                if(res.status === 200){
                    res.json().then((res) => {
                        if(res.returnCode === "20000"){
                            const {accessKeyID,accessKeySecret,securityToken} = res.data;
                            const client = new window.OSS.Wrapper({
                                region:'oss-cn-shanghai',
                                accessKeyId: accessKeyID,
                                accessKeySecret,
                                stsToken: securityToken,
                                bucket: 'bucket-elements'
                            })
                            setOssClient(client);
                        }
                    })
                }
            });

            fetch(serverAddress + "/collection/7/nft/"+ params.id, { method: "GET" })
                .then((res) => {
                    if (res.status === 200) {
                        res.json().then((res) => {
                            if (res.returnCode === "20000") {
                                let url = res.data.displayURL;
                                let nftImg = document.createElement("img");
                                nftImg.crossOrigin = "anonymous";
                                nftImg.src = url;
                                nftImg.onload = () => {
                                    setNftLoaded(true);
                                };
                                setNft(nftImg);

                                let tempImg = document.createElement("img");
                                tempImg.crossOrigin = "anonymous";
                                tempImg.src = "https://elements.cocafe.co/redpocket/assets/background-cocafe.png";
                                tempImg.onload = () => {
                                    setTemplateLoaded(true);
                                };
                                setTemplate(tempImg);

                                let frame = document.createElement('img');
                                frame.crossOrigin = 'anonymous';
                                frame.src = "https://elements.cocafe.co/redpocket/assets/frame.png";
                                frame.onload = () => {
                                    setFrameLoaded(true);
                                }
                                setFrame(frame);

                                let title = document.createElement('img');
                                title.crossOrigin = 'anonymous';
                                title.src = "https://elements.cocafe.co/redpocket/assets/title-cocafe.png";
                                title.onload = () => {
                                    setTitleLoaded(true);
                                }
                                setTitle(title);


                                setTokenId(res.data.tokenId);
                                let owner = res.data.ownedAt.slice(0,10);
                                setOwner(owner);
                                setName(res.data.name);
                                setDescription('');
                            }
                        });
                    }
                });
        }
    }, [state.isLogin]);

    useEffect(() => {
        if (nftLoaded && templateLoaded && frameLoaded && titleLoaded && client !== null) {
            // setLoaded(true);
            setTimeout(() => {
                const canvas = stageRef.current.toCanvas({pixelRatio: ratio});
                let path = "redpocket/poster/" + params.id + '.jpg';
                client.list({prefix:path}).then((res) => {
                    if(res.objects && res.objects.length > 0){
                        let url = "https://elements.cocafe.co/" + path;
                        let result = document.createElement("img");
                        result.src = url;
                        output.current.appendChild(result);
                        output.current.scrollTo(0,0);
                        result.onload = () => {
                            setLoaded(true);
                        }
                    }
                    else {
                        canvas.toBlob((blob) => {
                            const reader = new FileReader();
                            reader.readAsArrayBuffer(blob);
                            reader.onload = function (event) {
                                const buffer = new window.OSS.Buffer(event.target.result);
                                client.put(path, buffer).then((res) => {
                                    let url = "https://elements.cocafe.co/" + path;
                                    let result = document.createElement("img");
                                    result.src = url;
                                    output.current.appendChild(result);
                                    output.current.scrollTo(0,0);
                                    result.onload = () => {
                                        setLoaded(true);
                                    }
                                }).catch(function(err){
                                    console.log(err);
                                });
                            }
                        },'image/jpeg',1);
                    }
                });
            },500);
        }
    }, [nftLoaded,templateLoaded,frameLoaded,titleLoaded,client]);


    return(
        <div id="poster-container">
            <Loading text={'海报生成中...'} isShowing={!loaded}/>
            <div style={{padding: '18px 0 0 18px',position: 'absolute',left:'0', top: '0',zIndex:6}}>
                <BackButton />
            </div>
            <div style={{ position: "absolute", top: 0, left: 0, zIndex: 5,display: 'flex',alignItems:'center',justifyContent: 'center',width: '100%'}} ref={output}>
                <div className='reminder'>
                    <div>
                        长按保存海报，分享到朋友圈秀一秀吧！
                    </div>
                    <div>
                        <img src={point} />
                    </div>
                </div>

            </div>


            <Stage
                className="absolute left-0 top-0"
                width={deviceWidth}
                height={deviceWidth * (uiHeight / uiWidth)}
                ref={stageRef}
            >
                <Layer>
                    <Image image={templateImg} scale={{ x: scale * (uiWidth / imgWidth), y: scale * (uiWidth / imgWidth) }} />
                    <Image image={frame} width={308 * scale} height={308 * scale} x={39 * scale} y={179 * scale} />
                    <Image image={nft} width={263 * scale} height={263 * scale} x={58 * scale} y={198 * scale} />
                    <Image image={title} width={318 * scale} height={36 * scale} x={28 * scale} y={53 * scale} />
                    {/*<Text text="如花在野数字藏品" x={30 * scale} y={53 * scale} fontSize={40  * scale} fill="white"/>*/}
                    <Text text={`#` + tokenId + '/1000'} x={155 * scale} y={124 * scale} fontSize={14  * scale} lineHeight={2 * scale} fill="white"/>
                    <Rect x={128 * scale} y={122 * scale} width={132 * scale} height={30 * scale}
                        fillLinearGradientStartPoint={{ x: 10, y: 0 }} fillLinearGradientEndPoint={{ x: 122, y: 24 }}
                        fillLinearGradientColorStops={[0, "rgba(173, 173, 173, 0)", 0.4334, "rgba(128, 128, 128, 0.504712)", 0.875, "rgba(255, 255, 255, 0)"]}
                    />
                    {/*<Text text={description} width={268 * scale} x={55 * scale} y={481 * scale} fontSize={10 * scale} fontStyle="500" lineHeight={2 * scale} fill="white" align="center" />*/}
                    <Rect x={37 * scale} y={515 * scale} width={313 * scale} height={170 * scale} cornerRadius={8}
                          fillLinearGradientStartPoint={{ x: 0, y: 0 }} fillLinearGradientEndPoint={{ x: 350, y: 0 }}
                          fillLinearGradientColorStops={[0.1, "rgba(149, 12, 12, 0.7)", 0.95, "rgba(129, 193, 255, 0)"]}
                          stroke="rgba(255,255,255,0.2)"
                    />
                    <Text text="数字藏品信息" x={130 * scale} y={520 * scale} fontSize={18 * scale} fontStyle="500" lineHeight={2 * scale} fill="white" />

                    <Text text="发行方" x={50 * scale} y={566 * scale} fontSize={12  * scale} fill="#BCBCBC" />
                    <Text text="咖菲科技" x={270 * scale} y={566 * scale} fontSize={12 * scale} fill="white" align="right" />

                    <Text text="合约地址" x={50 * scale} y={595 * scale} fontSize={12 * scale} fill="#DCDCDC" />
                    <Text text={getSubStr('cfx:accv1ukxvgywcz74p7a7uva7wb0ygm8v769hx2y3nd')} x={155 * scale} y={595 * scale} fontSize={12 * scale} fill="#DCDCDC" align="right" />

                    <Text text="创建时间" x={50 * scale} y={625 * scale} fontSize={12 * scale} fill="#DCDCDC" />
                    <Text text= {owner}  x={255 * scale} y={625 * scale} fontSize={12 * scale} fill="#DCDCDC" align="right" />

                    <Text text="藏品名称" x={50 * scale} y={654 * scale} fontSize={12 * scale} fill="#DCDCDC" />
                    <Text text={name}  x={200 * scale} y={654 * scale} fontSize={12 * scale} fill="#DCDCDC"  align="right" />

                </Layer>
            </Stage>
        </div>
    );
}