import BackButton from "../components/BackButton";
import "./detail.scss";
import leftPlant from "../assets/left-plant.png";
import rightPlant from "../assets/right-plant.png";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {serverAddress} from "../App";
import {responseCode} from "../config";
import DutyFree from "../components/DutyFree";
import { Link } from "react-router-dom";

export default function Detail ({tokenId}){

    let {id} = useParams();
    let [nftInfo, setInfo] = useState({});
    const getSubStr = (str) => {
        let subStr1 = str.substr(0, 7);
        let subStr2 = str.substr(str.length - 19, 19);
        return subStr1 + "..." + subStr2;
    };

    useEffect(() => {
        try {
            fetch(
                serverAddress + "/collection/7/nft/" + id,
                {
                    method: "GET"
                }
            ).then((r) => {
                r.json().then((result) => {
                    if(result.returnCode === responseCode.success ){
                        const {ownedAt, name, ownerUserInfo,displayURL} = result.data;
                        setInfo({ownedAt,name,ownerUserInfo,displayURL});
                    }
                })
            } );
        } catch (error) {
            console.log("error", error);
        }

    },[id]);
    return(
        <div id="detail-container">
            <div style={{padding: '18px 0 0 18px',position: 'absolute',left:'0', top: '0'}}>
                <BackButton />
            </div>
            {/*<div id="light-container">*/}
            {/*    <img src={light} />*/}
            {/*</div>*/}
            <div className="home-title">
                <div className="plant-container">
                    <img src={leftPlant} />
                </div>
                <div>
                    {nftInfo.name}
                </div>
                <div className="plant-container">
                    <img src={rightPlant} />
                </div>

            </div>

            <div className='nft-container'>
                <div>
                    {'#' + id}
                </div>
                <div className='img-container'>
                    <img src={nftInfo.displayURL}/>
                </div>

            </div>
            <div className='info-container'>
                <div style={{marginBottom: '12px',fontSize: '18px',color: 'white' }}>
                    数字藏品信息
                </div>
                <div className='detail-container'>
                    <div className="detail-pair">
                        <div className='detail-key'>
                            发行方
                        </div>
                        <div className='detail-value'>
                            咖菲科技
                        </div>
                    </div>
                    <div className="detail-pair">
                        <div className='detail-key'>
                            合约地址
                        </div>
                        <div className='detail-value'>
                            {getSubStr('cfx:accv1ukxvgywcz74p7a7uva7wb0ygm8v769hx2y3nd')}
                        </div>
                    </div>
                    <div className="detail-pair">
                        <div className='detail-key'>
                            创建时间
                        </div>
                        <div className='detail-value'>
                            {nftInfo.ownedAt && nftInfo.ownedAt.slice(0,10)}
                        </div>
                    </div>
                    <div className="detail-pair">
                        <div className='detail-key'>
                            藏品名称
                        </div>
                        <div className='detail-value'>
                            {nftInfo.name}
                        </div>
                    </div>

                </div>

            </div>

            <div className='duty-container'>
                <DutyFree />
            </div>
            <div className='generate-poster'>
                <Link to={"/poster/" + id} >
                    生成海报
                </Link>

            </div>

        </div>
    );
}