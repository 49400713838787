import "./loading.scss"
import {Box, LinearProgress, linearProgressClasses} from "@mui/material";
import styled from "@emotion/styled";

export default function Loading( {text,isShowing} ){

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 16,
        margin:0,
        borderRadius: 10,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: 'rgba(0,0,0,0)',
        },
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: 'white',
        },
    }));
    return (
        <div className="fullscreen-loading" style={{display: isShowing ? 'flex' : 'none'}}>

            <Box className="loading-container"  sx={{ width: '115px',height: "16px",border: '1px solid rgba(255,255,255,1)',borderRadius:'10px'}}>
                <BorderLinearProgress />
            </Box>
            <div>
                {text}
            </div>

        </div>
    )
}