import "./home.scss";
import leftPlant from "../assets/left-plant.png";
import rightPlant from "../assets/right-plant.png";
import DutyFree from "../components/DutyFree";
import {useNavigate} from "react-router";
import {useContext} from "react";
import {AuthContext} from "../App";
import nft from "../assets/nft.jpg";



export function Home(){
    let navigate = useNavigate();
    const { state } = useContext(AuthContext);
    const claim = () => {
        if(state.isLogin){
            navigate('/claim')
        }else{
            navigate('/login');
        }
    }
    return(
        <div id="home-container">
            {/*<div id="light-container">*/}
            {/*    <img src={light} />*/}
            {/*</div>*/}
            <div className="home-title">
                <div className="plant-container">
                    <img src={leftPlant} />
                </div>
                <div>
                    咖菲科技数字藏品
                </div>
                <div className="plant-container">
                    <img src={rightPlant} />
                </div>

            </div>
            <div className="nft-container">
                <img src={nft} />
            </div>

            <div className="name-container">
                大唐仕女｜虎年新春
            </div>



            <div id='retrieve-button' onClick={claim}>
                领取我的数字藏品
            </div>

            <div className='duty-container'>
                <DutyFree />
            </div>



        </div>
    )
}