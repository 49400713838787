import { useContext } from "react";
import { portalAddress, AuthContext } from "../App";
import { responseCode } from "../config";

export default function useLogout() {
    const { state, dispatch } = useContext(AuthContext);

    const logout = async() => {
        try {
            const response = await fetch(
                portalAddress + "/user/logout",
                {
                    method: "POST",
                    headers: {"accessToken":state.accessToken},
                }
            );
            const json = await response.json();
            return new Promise((resolve, reject) => {
                if (json.returnCode === responseCode.success) {
                    dispatch({
                        type: "LOGOUT",
                    });
                    resolve(true);
                }else{
                    resolve(false);
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    }
    return logout;
}