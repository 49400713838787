import back from "../assets/back.png";
import {useNavigate} from "react-router";

export default function BackButton(){
    let navigate = useNavigate();
    const goBack = () => {
        navigate(-1)
    }
    return (
        <div className='back-icon' onClick={goBack} style={{width:'24px', height:'24px'}}>
            <img src={back} style={{width:'100%',height:'100%'}}/>
        </div>
    )
}