import { useContext } from "react";
import { portalAddress, AuthContext } from "../App";
import { responseCode } from "../config";

export default function useLogin() {
  const { dispatch } = useContext(AuthContext);

  const post = async(phone, code) => {
    try {
      const response = await fetch(
        portalAddress + "/user/login", 
        {
          method: "POST",
          body: JSON.stringify({ phone: phone, code: code }),
        }
      );
      const json = await response.json();
      if (json.returnCode === responseCode.success) {
        dispatch({
          type: "LOGIN",
          payload: {
            isLogin: true,
            userId: json.data.userId,
            accessToken: json.data.accessToken,
          },
        });
        return true;
      }
      console.log(json.message);
      return false;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }

  return post;
}